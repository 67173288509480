@import "./bootstrap.css";
@import "./animate.css";
@import url("https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

:root {
    --Primary-p3: #160d3f;
    --Primary-p2: linear-gradient(135deg, #8156ec 5.05%, #4e80f1 100.04%);
    --Primary-p1: #4c1496;
    --Secondary-s1: #f4cb37;
    --Black-opacity-300: rgba(22, 20, 35, 0.4);
    --Black-opacity-200: rgba(22, 20, 35, 0.3);
    --White-opacity-50: rgba(255, 255, 255, 0.10);
}

html {
    cursor: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAHhSURBVHgBxZcxjoJAFIb/7NrY0VhsYwYTGxMDiaUN25hYbSzswRvsDdgTbDwBHMEbwA0kMbGFeAG4ATtv3dlgRGRgxC95cZyB8Pjf/wYAbpPz8PBgXu6sMx7232/n5IWw8QRy0zRzz/Py9XodoINyXCVgWVZO2LYtlHhOAgLGGCURQCH3THiBYRiYz+caH1rowJhXChBBEIhyuFCAlAIENya4MbHZbD5wNqaGFkgnoGkauCkxHA5NnNuzVQJVlJZAkKZpHsdxPh6PY7QwprQCAlKCdwUmkwlbLpcMDzBmpQJFSAk0NOZrxZpLd0j1rkO/38dgMMDxeNT534RHhpbUVkDguq5QwoICpBMQxpzNZilqGrOxCcsQxpxOpxrvDoazKRu3qbQCRQrGrHyKKjNhGWTM0WiE/X7/hgbGbKWAgN4nUGHMHhSQZRlWq1XpWpIkledKJ0AXoyDDUYi5MAx/h5CUWboLoiiCruvYbrdlyzSp34iwdQK73Q6HwyHhQ/90OkW+7/+rQWZdLBYGFD4h772SuXQMtZugyU5YywMkN909N9Q7FOzx0glQ3f9MFqJD6n6YxFSaIlQ6mkcNqhTwC+MED6IqAQcdoGIn/Oz1esxxnO/C3BePCB3CcOkZq+6JP+AEEaq+WJESAAAAAElFTkSuQmCC'), auto;

    & a:hover {
        cursor: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAHhSURBVHgBxZcxjoJAFIb/7NrY0VhsYwYTGxMDiaUN25hYbSzswRvsDdgTbDwBHMEbwA0kMbGFeAG4ATtv3dlgRGRgxC95cZyB8Pjf/wYAbpPz8PBgXu6sMx7232/n5IWw8QRy0zRzz/Py9XodoINyXCVgWVZO2LYtlHhOAgLGGCURQCH3THiBYRiYz+caH1rowJhXChBBEIhyuFCAlAIENya4MbHZbD5wNqaGFkgnoGkauCkxHA5NnNuzVQJVlJZAkKZpHsdxPh6PY7QwprQCAlKCdwUmkwlbLpcMDzBmpQJFSAk0NOZrxZpLd0j1rkO/38dgMMDxeNT534RHhpbUVkDguq5QwoICpBMQxpzNZilqGrOxCcsQxpxOpxrvDoazKRu3qbQCRQrGrHyKKjNhGWTM0WiE/X7/hgbGbKWAgN4nUGHMHhSQZRlWq1XpWpIkledKJ0AXoyDDUYi5MAx/h5CUWboLoiiCruvYbrdlyzSp34iwdQK73Q6HwyHhQ/90OkW+7/+rQWZdLBYGFD4h772SuXQMtZugyU5YywMkN909N9Q7FOzx0glQ3f9MFqJD6n6YxFSaIlQ6mkcNqhTwC+MED6IqAQcdoGIn/Oz1esxxnO/C3BePCB3CcOkZq+6JP+AEEaq+WJESAAAAAElFTkSuQmCC'), auto;
    }

    & a:active {
        cursor: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAIySURBVHgBxZc/bupAEMY/8lJQIZcpHQEVjXsat1ShoX6+AeEGvBO83MDmBHFHQUF6ipAeyb6BiZBQRMFmPrAj45jEJgv5SSPYP3jH8+3MLsARlFLPYn8zffdiU2jkCvmLm/IRilUyQ3diBi4BnVB77lJ9xM3Ms8W60I081BB7ELNSbY8Lxm2LEsVSPUMHXKTEXDqnEmegg3iTBQwzw5pyiAs5OfNN6CRe9FEsoiPpITHu/v+4FJmwKsMwVLPZpFMmfgHleZ4KgoCRoNnQxLE68JiXWhIFTKdTtNttSqFFjuvMwh72BchETqjpgG3b6PV6Vq1WM8bj8Yt0+2JL6CB+czdviBKkkUgkcpi4AJ8cINwT8cbs40T+FJw37Ha7sKzDekNJttutUa/Xq7PZ7Fa6nnAmciOQ4LpuIkdprqABx3GQVGXkVMyzO5AgkWCG8A5ROEW1OsBItFotu9PpsIbY0Hh3+HIP5GUHClZMrRFIKFMxf5SGx6hWqzBNE6vV6qZSqdwsFotX7CvsG06klARpvquY19CIvC3KcrIDYRhiNBqh3+/vNE/Bw+kl5yc/OrAOJOAun0wmkfQHDHEURbt+0V01Go17nOGAOnBAjuTkikaC4XD4MSa1gGNB0QeXkmC5XGIwGPDrP7E5NFC4DlBz3/chkXgSqLMfD83X6/Vc+nYNpp9UQn61obMSxnYstA4+/jiVq4RFJbjFmSjqQFhgXNTxHDY2mw1TTt9dsQTfSfWJd0dkCoXCwWzuAAAAAElFTkSuQmCC'), auto;
    }

    & embed:hover {
        cursor: pointer;
        /* cursor: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAHhSURBVHgBxZcxjoJAFIb/7NrY0VhsYwYTGxMDiaUN25hYbSzswRvsDdgTbDwBHMEbwA0kMbGFeAG4ATtv3dlgRGRgxC95cZyB8Pjf/wYAbpPz8PBgXu6sMx7232/n5IWw8QRy0zRzz/Py9XodoINyXCVgWVZO2LYtlHhOAgLGGCURQCH3THiBYRiYz+caH1rowJhXChBBEIhyuFCAlAIENya4MbHZbD5wNqaGFkgnoGkauCkxHA5NnNuzVQJVlJZAkKZpHsdxPh6PY7QwprQCAlKCdwUmkwlbLpcMDzBmpQJFSAk0NOZrxZpLd0j1rkO/38dgMMDxeNT534RHhpbUVkDguq5QwoICpBMQxpzNZilqGrOxCcsQxpxOpxrvDoazKRu3qbQCRQrGrHyKKjNhGWTM0WiE/X7/hgbGbKWAgN4nUGHMHhSQZRlWq1XpWpIkledKJ0AXoyDDUYi5MAx/h5CUWboLoiiCruvYbrdlyzSp34iwdQK73Q6HwyHhQ/90OkW+7/+rQWZdLBYGFD4h772SuXQMtZugyU5YywMkN909N9Q7FOzx0glQ3f9MFqJD6n6YxFSaIlQ6mkcNqhTwC+MED6IqAQcdoGIn/Oz1esxxnO/C3BePCB3CcOkZq+6JP+AEEaq+WJESAAAAAElFTkSuQmCC'), auto; */
    }

    .overflow-hidden {
        overflow: hidden;
    }

    .text-wrap {
        text-wrap: nowrap;
    }

    scroll-behavior: smooth !important;
}

body {
    background: var(--Primary-p3);
    height: 100vh;
    overflow: auto;
    overflow-x: hidden;

    & p {
        padding: 0px;
        margin: 0px;
    }

    & .text-shadow {
        text-transform: uppercase;
        text-shadow: 0.0px 10.0px 0.02px #000, 9.8px 2.1px 0.02px #000, 4.2px -9.1px 0.02px #000, -8.0px -6.0px 0.02px #000, -7.6px 6.5px 0.02px #000, 4.8px 8.8px 0.02px #000, 9.6px -2.8px 0.02px #000, -0.7px -10.0px 0.02px #000, -9.9px -1.5px 0.02px #000, -3.5px 9.4px 0.02px #000, 8.4px 5.4px 0.02px #000, 7.1px -7.0px 0.02px #000, -5.4px -8.4px 0.02px #000, -9.4px 3.5px 0.02px #000, 1.4px 9.9px 0.02px #000, 10.0px 0.8px 0.02px #000, 2.9px -9.6px 0.02px #000, -8.7px -4.8px 0.02px #000, -6.6px 7.5px 0.02px #000, 5.9px 8.0px 0.02px #000, 9.1px -4.1px 0.02px #000, -2.1px -9.8px 0.02px #000, -10.0px -0.1px 0.02px #000, -2.2px 9.8px 0.02px #000, 9.1px 4.2px 0.02px #000, 6.1px -8.0px 0.02px #000, -6.5px -7.6px 0.02px #000, -8.8px 4.7px 0.02px #000, 2.7px 9.6px 0.02px #000, 10.0px -0.6px 0.02px #000, 1.5px -9.9px 0.02px #000, -9.3px -3.6px 0.02px #000, -5.5px 8.4px 0.02px #000, 7.0px 7.2px 0.02px #000, 8.5px -5.3px 0.02px #000, -3.4px -9.4px 0.02px #000, -9.9px 1.3px 0.02px #000, -0.8px 10.0px 0.02px #000, 9.6px 2.9px 0.02px #000, 4.9px -8.7px 0.02px #000, -7.5px -6.7px 0.02px #000, -8.1px 5.9px 0.02px #000, 4.0px 9.2px 0.02px #000, 9.8px -2.0px 0.02px #000, 0.2px -10.0px 0.02px #000, -9.7px -2.3px 0.02px #000, -4.3px 9.0px 0.02px #000, 7.9px 6.1px 0.02px #000;
    }
}

.font-2p {
    font-family: "Press Start 2P";
}

#header {
    position: fixed;
    top: 0;
    z-index: 1000;
    width: 100vw;
    overflow-x: hidden;
    height: auto;
    transition: all 0.35 ease;
    background-color: var(--Primary-p3);
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    color: #fff;
}

.navbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 1.25rem;

    & .brand {
        height: 40px;

        & img {
            height: 100%;
        }
    }

    & .menu-play {
        position: relative;

        & p {
            position: absolute;
            color: #000;
            font-family: "Press Start 2P";
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 28px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 120px;
        }
    }
}

.menu {
    overflow: hidden;
    margin-right: auto;
    text-align: center;
    background: var(--Black-opacity-200, rgba(22, 20, 35, 0.30));
    backdrop-filter: blur(75px);

    & .menu-inner {
        transition: all 0.4s ease-in-out;
        display: inline-flex;
        flex-direction: row;
        column-gap: 2rem;
        align-items: center;
        margin: 0px;

        & li {
            list-style: none;
            margin-bottom: none;


            & a {
                color: #fff;
                text-decoration: none;
                margin-right: auto;
            }

            & img {
                display: none;
            }

            & a:hover {
                color: var(--Secondary-s1);
            }
        }
    }

    & .menu-item.active {
        & a {
            color: var(--Secondary-s1) !important;
        }
    }
}

#btn-menu {
    object-fit: cover;
    background-repeat: no-repeat;
    height: 32px;
    width: 32px;
    margin-left: auto;

    & .close {
        display: none;
    }

    & .open {
        display: block;
    }
}

#btn-menu.active {
    & .open {
        display: none;
    }

    & .close {
        display: block;
    }
}

/* MAIN CSS */

main {
    height: fit-content;
    overflow: hidden;

    & .play {
        margin-top: 70px;
        position: relative;
        height: fit-content;

        & .background {
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
            height: fit-content;
            max-height: 90vh;
            min-height: 50vh;
        }

        & .object-bg {
            position: absolute;
            top: 16px;
            z-index: 0;
            bottom: 0px;
            left: -50px;
            position: absolute;
            -webkit-animation: linear infinite;
            animation-name: run;
            -webkit-animation-duration: 100s;
        }


        & .box-bg {
            position: absolute;
        }

        & .sht {
            height: 90px;
            position: absolute;
            top: 100px;
            z-index: 3;
        }

        & .sht-left {
            left: 4rem;
        }

        & .sht-right {
            right: 4rem;
        }

        & .sht-center {
            top: 28rem;
            right: 65%;
            left: 35%;
            transform: translate(-20%, -50%);
        }

        & .text-play {
            position: absolute;
            left: 20%;
            top: 20%;
            transform: translate(-50%, -50%);
            width: 60vw;
        }

        & .character {
            position: absolute;
            bottom: 80px;
        }

        & .dog {
            left: 60px;
        }

        & .pepe {
            right: 60px;
        }

        & .btn-play {
            position: absolute;
            right: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: fit-content;
            bottom: 100px;


            & p {
                color: #000;
                font-family: "Press Start 2P";
                font-size: 24px;
                font-style: normal;
                font-weight: 400;
                line-height: 40px;
                text-transform: uppercase;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: fit-content;
                text-wrap: nowrap;
            }
        }


    }

    #about {
        padding: 80px 0px;
        background: var(--White-opacity-50, rgba(255, 255, 255, 0.10));

        & .title {
            padding-left: 15px;
            overflow: hidden;
            color: var(--White-opacity-900, #FFF);
            text-overflow: ellipsis;
            white-space: nowrap;

            /* Press Start 2P/Heading/H4 */
            -webkit-text-stroke-width: 8;
            -webkit-text-stroke-color: var(--Black-opacity-900, #161423);
            font-family: "Press Start 2P";
            font-size: 32px;
            font-style: normal;
            font-weight: 400;
            line-height: 52px;
            /* 162.5% */
            text-transform: uppercase;
        }

        & .text {
            color: #FFF;
            font-family: Inter;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 32px;
        }



        .carousel {
            display: flex;
            flex-direction: row;
            .swiper-pagination-bullet{
                background: rgba(255, 255, 255, 0.3);
            }

            .swiper-pagination-bullet-active {
                background: white !important;
            }
        }

        .carousel__slide {
            position: relative;
            flex: 0 0 100%;
            padding: 10px;
        }

        .carousel__slide__content {
            border-radius: 10px;
            width: 100%;
            height: 100%;

            & #my-video {
                width: 100%;
                object-fit: contain;
                border-radius: 20px;
                height: 90%;
            }
        }

        & .indicate-button {
            fill-opacity: 0.3;

        }

        & .indicate-button.active {
            fill-opacity: 1;
        }

    }

    #character {
        padding: 70px 0px;

        & .title {
            overflow: hidden;
            color: var(--White-opacity-900, #FFF);
            text-overflow: ellipsis;
            white-space: nowrap;
            -webkit-text-stroke-width: 8;
            -webkit-text-stroke-color: var(--Black-opacity-900, #161423);
            font-family: "Press Start 2P";
            font-size: 32px;
            font-style: normal;
            font-weight: 400;
            line-height: 52px;
            text-transform: uppercase;
        }

        & img {
            object-fit: contain;
            height: fit-content;
        }

        & .character-img {
            width: 100%;
            height: 100%;
        }

        & .character-name {
            overflow: hidden;
            color: #8A57F6;
            text-align: center;
            text-overflow: ellipsis;
            white-space: nowrap;
            -webkit-text-stroke-width: 8;
            -webkit-text-stroke-color: var(--Black-opacity-900, #161423);
            font-family: "Press Start 2P";
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: 40px;
            text-transform: uppercase;
        }

        & .character-name.meme {
            color: var(--Secondary-s1);
        }
    }

    .sponser {
        background: linear-gradient(rgba(129, 86, 236, 1),rgba(78, 128, 241, 1));
        padding: 72px 0;
        img {
            height: 80px;
        }
    }

}

/* FOOTER */

#footer {
    background: rgb(45,37,82);
    padding: 44px 0px;

    & .nav-item {
        color: var(--White-opacity-900, #FFF);
        font-family: "Press Start 2P";
        font-size: 11px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
    }

    & .text {
        color: var(--White-opacity-900, #FFF);
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
    }

    & .play-box {
        position: relative;
        height: fit-content;

        & .text {
            position: absolute;
            width: 100%;
            text-align: center;
            text-wrap: nowrap;
            color: #000;
            font-family: "Press Start 2P";
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 28px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        & .box-yellow {
            width: 172px;
        }
    }



    & .copy-right {
        color: var(--White-opacity-300, rgba(255, 255, 255, 0.40));
        font-family: Inter;
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px;
    }


}

/* CAMPAIGN*/
#campaign {
    padding-top: 52px;
    .title {
        overflow: hidden;
        color: #FFF;
        text-overflow: ellipsis;
        white-space: nowrap;
        -webkit-text-stroke-color:#161423;
        font-family: "Press Start 2P";
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
        text-transform: uppercase;
    }

    .sub-title{
        color: #FFFFFF;
        /* Inter/Heading/H6 */
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px; /* 160% */
        text-transform: uppercase;
    }

    .bitlayer{
        color: #F4CB37;
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        text-decoration-line: underline;
        text-transform: uppercase;
    }

    .hr {
        background: rgba(255, 255, 255, 0.20);
    }

    .comsume-text{
        color: rgba(255, 255, 255, 0.60);
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        text-transform: uppercase;
    }

    .comsume-value{
        color: #F4CB37;
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
        text-transform: uppercase;
    }

    .ranking {
        img {
            width: -webkit-fill-available;
        }
    }

    table {
        margin-top: 64px;
        width: 100%;
        border-collapse: separate;
        border-spacing: 0 10px;
        border-radius: 12px;
        border: 1px solid rgba(255, 255, 255, 0.20);
        background: #160D3F;
        th {
            color: rgba(255, 255, 255, 0.70);
            font-family: Inter;
            font-size: 15px;
            font-style: normal;
            font-weight: 600;
            padding: 20px;
        }

        td {
            padding: 0 20px;
        }

        .rank {
            color: #FFFFFF;
            font-family: "Press Start 2P";
            font-size: 11px;
            font-style: normal;
            font-weight: 400;
        }

        .address {
            color:  #FFF;
            font-family: Inter;
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
        }

        .amount {
            color: #FFFFFF;
            font-family: Inter;
            font-size: 15px;
            font-style: normal;
            font-weight: 600;
            line-height: 28px;
        }

    }
}

@media (max-width: 768px) {
    .menu {
        position: fixed;
        top: -330px;
        right: 0px;
        left: 0px;
        background-color: var(--Black-opacity-200);
        column-gap: 2rem;
        z-index: 999;
        margin: 0 auto;
        transition: all 0.4s ease-in-out;

        & .brand {
            height: 28px;
        }

        & .menu-inner {
            width: 100vw;
            display: flex;
            flex-direction: column;
            row-gap: 0rem;
            align-items: start;
            padding: 0px;
            /* padding: 1rem; */

            & .menu-item {
                display: flex;

                width: 100%;
                padding: 24px 8px;
                justify-content: center;
                border-bottom: 0.5px solid var(--White-opacity-50, rgba(255, 255, 255, 0.10));

                & a {
                    padding-left: 20px;
                    flex-grow: 1;
                    text-align: start;
                }

                & img {
                    display: none;
                }
            }

            & .menu-item.active img {
                display: flex;
            }
        }
    }

    .menu.active {
        top: 56px;
        transition: all 0.4s ease-in-out;
    }

    /* MAIN RESPONSIVE */
    main {
        /* overflow: hidden; */

        & .play {
            .background {
                max-height: 60vh;
            }

            & .sht {
                height: 20px;
                top: 50px;
            }

            & .sht-center {
                top: 170px;
            }

            & .character {
                height: 120px;
            }

            & .btn-play {
                width: 125px;

                & img {
                    width: 100%;
                }
            }

            & .dog {
                left: 30px;
            }

            & .pepe {
                right: 30px;
            }
        }

        .move-left-top {
            height: 32px !important;
        }

        .quotation {
            height: 56px;
        }

        .sponser {
            img {
                height: 64px;
            }
        }
    }

    .fs-md-11px {
        font-size: 11px !important;
        line-height: 20px !important;
    }

    .width-mobile-100 {
        min-width: 90px;
    }

    .fs-md-20px {
        font-size: 20px !important;
        line-height: 32px !important;
    }

    #footer {
        & .box-yellow {
            width: 100px !important;
        }
    }
}

.hover:hover {
    /* cursor: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAIySURBVHgBxZc/bupAEMY/8lJQIZcpHQEVjXsat1ShoX6+AeEGvBO83MDmBHFHQUF6ipAeyb6BiZBQRMFmPrAj45jEJgv5SSPYP3jH8+3MLsARlFLPYn8zffdiU2jkCvmLm/IRilUyQ3diBi4BnVB77lJ9xM3Ms8W60I081BB7ELNSbY8Lxm2LEsVSPUMHXKTEXDqnEmegg3iTBQwzw5pyiAs5OfNN6CRe9FEsoiPpITHu/v+4FJmwKsMwVLPZpFMmfgHleZ4KgoCRoNnQxLE68JiXWhIFTKdTtNttSqFFjuvMwh72BchETqjpgG3b6PV6Vq1WM8bj8Yt0+2JL6CB+czdviBKkkUgkcpi4AJ8cINwT8cbs40T+FJw37Ha7sKzDekNJttutUa/Xq7PZ7Fa6nnAmciOQ4LpuIkdprqABx3GQVGXkVMyzO5AgkWCG8A5ROEW1OsBItFotu9PpsIbY0Hh3+HIP5GUHClZMrRFIKFMxf5SGx6hWqzBNE6vV6qZSqdwsFotX7CvsG06klARpvquY19CIvC3KcrIDYRhiNBqh3+/vNE/Bw+kl5yc/OrAOJOAun0wmkfQHDHEURbt+0V01Go17nOGAOnBAjuTkikaC4XD4MSa1gGNB0QeXkmC5XGIwGPDrP7E5NFC4DlBz3/chkXgSqLMfD83X6/Vc+nYNpp9UQn61obMSxnYstA4+/jiVq4RFJbjFmSjqQFhgXNTxHDY2mw1TTt9dsQTfSfWJd0dkCoXCwWzuAAAAAElFTkSuQmCC'), auto; */
}

.hover:active {
    cursor: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAIySURBVHgBxZc/bupAEMY/8lJQIZcpHQEVjXsat1ShoX6+AeEGvBO83MDmBHFHQUF6ipAeyb6BiZBQRMFmPrAj45jEJgv5SSPYP3jH8+3MLsARlFLPYn8zffdiU2jkCvmLm/IRilUyQ3diBi4BnVB77lJ9xM3Ms8W60I081BB7ELNSbY8Lxm2LEsVSPUMHXKTEXDqnEmegg3iTBQwzw5pyiAs5OfNN6CRe9FEsoiPpITHu/v+4FJmwKsMwVLPZpFMmfgHleZ4KgoCRoNnQxLE68JiXWhIFTKdTtNttSqFFjuvMwh72BchETqjpgG3b6PV6Vq1WM8bj8Yt0+2JL6CB+czdviBKkkUgkcpi4AJ8cINwT8cbs40T+FJw37Ha7sKzDekNJttutUa/Xq7PZ7Fa6nnAmciOQ4LpuIkdprqABx3GQVGXkVMyzO5AgkWCG8A5ROEW1OsBItFotu9PpsIbY0Hh3+HIP5GUHClZMrRFIKFMxf5SGx6hWqzBNE6vV6qZSqdwsFotX7CvsG06klARpvquY19CIvC3KcrIDYRhiNBqh3+/vNE/Bw+kl5yc/OrAOJOAun0wmkfQHDHEURbt+0V01Go17nOGAOnBAjuTkikaC4XD4MSa1gGNB0QeXkmC5XGIwGPDrP7E5NFC4DlBz3/chkXgSqLMfD83X6/Vc+nYNpp9UQn61obMSxnYstA4+/jiVq4RFJbjFmSjqQFhgXNTxHDY2mw1TTt9dsQTfSfWJd0dkCoXCwWzuAAAAAElFTkSuQmCC'), auto;
}

/* custom animation */

@keyframes run {
    0% {
        left: 0;
    }

    50% {
        left: -100%;
    }

    100% {
        right: 0;
    }
}

